import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/recruitment',
        name: 'recruitment',
        component: () => import('@/views/recruitment'),
        meta: {
            title: '人才招聘'
        }
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('@/views/productDetail'),
        meta: {
            title: '产品详情'
        }
    },
    {
        path: '/dynamicDetail',
        name: 'dynamicDetail',
        component: () => import('@/views/dynamicDetail'),
        meta: {
            title: '动态新闻详情'
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router