<template>
  <div class="no_change_px">
    <div class="apply_dialog">
      <el-dialog :visible.sync="applyShow" :show-close="false">
        <div class="dialog_close">
          <img
            src="@/assets/apply_close.png"
            @click="applyShow = false"
            alt=""
          />
        </div>
        <p class="apply_title">申请演示</p>
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item prop="name">
            <div class="form_item">
              <div class="item_lable">
                <img class="lable_icon" src="@/assets/apply_ico2.png" alt="" />
                <div class="lable_name">姓名</div>
              </div>
              <div class="item_content">
                <el-input
                  class="item_input item_input_name"
                  type="text"
                  v-model="form.name"
                  placeholder="请输入您的姓名"
                />
                <el-select class="sexSelect" v-model="form.sex" placeholder="">
                  <el-option
                    v-for="item in sexList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="mobile">
            <div class="form_item">
              <div class="item_lable">
                <img class="lable_icon" src="@/assets/apply_ico3.png" alt="" />
                <div class="lable_name">手机</div>
              </div>
              <div class="item_content">
                <el-input
                  class="item_input"
                  type="text"
                  v-model="form.mobile"
                  placeholder="请输入您的手机号"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="email">
            <div class="form_item">
              <div class="item_lable">
                <img class="lable_icon" src="@/assets/apply_ico4.png" alt="" />
                <div class="lable_name">邮箱</div>
              </div>
              <div class="item_content">
                <el-input
                  class="item_input"
                  type="text"
                  v-model="form.email"
                  placeholder="请输入您的邮箱"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="company">
            <div class="form_item">
              <div class="item_lable">
                <img class="lable_icon" src="@/assets/apply_ico5.png" alt="" />
                <div class="lable_name">公司</div>
              </div>
              <div class="item_content">
                <el-input
                  class="item_input"
                  type="text"
                  v-model="form.company"
                  placeholder="所属公司/机构/组织"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="remark">
            <div class="form_item">
              <el-input
                class="item_des"
                type="textarea"
                v-model="form.remark"
                placeholder="请简单的描述您的需求，以便我们更精准的提供服务"
              />
            </div>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button
              style="width: 160px"
              type="primary"
              round
              @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { registerApi } from "@/api/index.js";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      applyShow: false,
      sexList: [
        {
          id: 0,
          name: "男士",
        },
        {
          id: 1,
          name: "女士",
        },
      ],
      form: {
        name: "",
        sex: 0,
        mobile: "",
        email: "",
        company: "",
        remark: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        mobile: [{ required: true, validator: checkPhone, trigger: "blur" }],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        company: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.$bus.$on("showApply", this.showApplyFn);
  },
  destroyed() {
    this.$bus.$off("showApply");
  },
  methods: {
    showApplyFn() {
      this.applyShow = true;
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const data = { ...this.form };
          const res = await registerApi(data);
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "提交成功，我们会尽快与您联系！",
            });
            this.$refs.ruleForm.resetFields();
            this.applyShow = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.no_change_px {
  .apply_dialog {
    .el-dialog {
      width: 500px;
      border-radius: 10px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 70px;
      margin: 0 !important;

      .el-dialog__header,
      .el-dialog__footer,
      .el-dialog__body {
        padding: 0;
      }

      .dialog_close {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 16px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .apply_title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #148ff9;
        margin-bottom: 40px;
      }

      .form_item {
        width: 100%;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 0 12px;
        display: flex;
        align-items: center;

        .item_lable {
          display: flex;
          align-items: center;

          .lable_icon {
            width: 18px;
            height: 18px;
          }

          .lable_name {
            color: #333333;
            font-size: 14px;
            padding-left: 10px;
          }
        }

        .item_content {
          margin-left: 10px;
          flex: 1;
          display: flex;
          position: relative;

          .item_input {
            flex: 1;

            .el-input__inner {
              border: none;
              padding: 0;
            }
          }

          .item_input_name {
            padding-right: 80px;
          }

          .sexSelect {
            width: 80px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            .el-input__inner {
              border: none;
            }
          }
        }

        .item_des {
          resize: none;
          width: 100%;
          height: 90px;
          padding: 10px 0;

          .el-textarea__inner {
            resize: none;
            height: 100%;
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
