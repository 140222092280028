import Vue from 'vue';

Vue.prototype.$func = {
    isElementInViewport: (el) => {
        if (el) {
            let rect = el.getBoundingClientRect();
            return !(
                rect.top >=
                (window.innerHeight || document.documentElement.clientHeight) ||
                rect.bottom <= 10
            );
        }
    }
}