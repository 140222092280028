<template>
  <div id="app">
    <router-view />
    <Apply />
  </div>
</template>

<script>
import Apply from '@/components/apply';
export default {
  name: "App",
  components:{
    Apply
  }
};
</script>

<style lang='scss'>
html,
body {
  background: #edf0f6;
}
</style>
