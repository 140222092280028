import axios from 'axios';
import {Message} from 'element-ui';

// 请求超时时间
axios.defaults.timeout = 10000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API


// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    });


// 响应拦截器
axios.interceptors.response.use(
    response => {
        const res = response.data
        if (res.error) {
            Message({showClose: true, type: 'warning', offset: 70, message: res.error});
            return {data: {}};
        }
        return response
    },
    // 服务器状态码不是200的情况    
    error => {
        // if (error.response.status) {
        // Message({
        //     showClose: true,
        //     message: '网络错误'
        // });
        return Promise.reject(error.response);
        // }
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params, spell) {
    return new Promise((resolve, reject) => {
        if (spell) {
            for (let key in params) {
                let i = 0
                if (i == 0) {
                    url += '?' + key + '=' + params[key]
                } else {
                    url += '&' + key + '=' + params[key]
                }
                i++
            }
        }
        axios.get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, spell) {
    return new Promise((resolve, reject) => {
        if (spell) {
            for (let key in params) {
                let i = 0
                if (i == 0) {
                    url += '?' + key + '=' + params[key]
                } else {
                    url += '&' + key + '=' + params[key]
                }
                i++
            }
        }
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}

export function put(url, params, spell) {
    return new Promise((resolve, reject) => {
        if (spell) {
            for (let key in params) {
                let i = 0
                if (i == 0) {
                    url += '?' + key + '=' + params[key]
                } else {
                    url += '&' + key + '=' + params[key]
                }
                i++
            }
        }
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}

export function del(url, params, spell) {
    return new Promise((resolve, reject) => {
        if (spell) {
            for (let key in params) {
                let i = 0
                if (i == 0) {
                    url += '?' + key + '=' + params[key]
                } else {
                    url += '&' + key + '=' + params[key]
                }
                i++
            }
        }
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}